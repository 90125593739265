body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Header {
  background-color: #2a2a2a;
  padding-left: 0px
}

.Footer {
  text-align: left;
  height: 50px;
  background-color: #3a3a3a;
  color: #f0f0f0;
  padding-left: 20px;
  padding-top: 20px;
  font-size: 11px;
}

@media only screen and (max-width: 600px){
  .Header {
    background-color: #2a2a2a;
    padding-left: 0px;
    width: 100%
  }
}

.divScadt {
	position: relative;
	margin-top: 0px;
}

.divScadt p {
	color: Black;
	padding: 2px;
	margin: 0px;
	text-align: left
}

.divScadt p::first-letter {
	font-size: 18px;
	color: Red;
	font-weight: bold;
}

.hrScadt {
	height: 1px;
	border: none;
	color: #333;
	background-color: #333;
	width: 97%;
}
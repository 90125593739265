.App {
  text-align: center;
}

.App-content {
  overflow: hidden;
	width: 100%;
	margin-top: 90px;
	margin-bottom: 5px
}

@media only screen and (max-width: 600px){
	.App-content {
		overflow: hidden;
		width: 100%;
		margin-top: 65px;
		margin-bottom: 5px
	}
}

.App-content-left {
  float: left;
  width: 288px;
  margin-left: 20px;
  position: relative;
  left: 0px
}

.App-content-right {
	margin: 2px;
  position: relative;
	overflow: hidden;
	box-shadow: 1px 2px 4px 1px rgba(0,0,0,0.16) !important;
}
